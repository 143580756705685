<template>
  <page-layout :title="$t('change_password.title')" :has-back-button="true">
    <template #header-actions>
      <base-button
        :label="$t('general.button_label.save_changes')"
        :loading="loading"
        type="primary"
        @click="save"
      />
    </template>
    <el-alert type="info" :closable="false" show-icon>
      {{ $t('change_password.alert') }}
    </el-alert>
    <el-form
      ref="form"
      :key="key"
      :model="form"
      autocomplete="off"
      id="changePassword"
      label-position="top"
      v-loading="loading"
      @submit.prevent.native="save"
    >
      <base-section
        no-padding
        :with-border="false"
        :style="{ marginTop: '26px' }"
      >
        <base-input
          class="login-password"
          :label="$t('change_password.old_password_label')"
          ref="oldPasswordInput"
          prop="oldPassword"
          :type="oldPasswordInput"
          :error="oldPasswordError"
          v-model.trim="form.oldPassword"
          name="oldPassword"
          :validations="$v.form.oldPassword"
          auto-complete="off"
          :max-width="376"
        >
          <template #afterInput>
            <span class="show-pwd" @click="showPwd('oldPasswordInput')">
              <svg-icon
                :icon="oldPasswordInput === 'password' ? 'eye' : 'eye-open'"
              />
            </span>
          </template>
        </base-input>
      </base-section>
      <base-section no-padding :with-border="false">
        <base-input
          class="login-password"
          :label="$t('change_password.new_password_label')"
          ref="newPasswordInput"
          prop="newPassword"
          :type="newPasswordInput"
          :error="newPasswordError"
          v-model.trim="form.newPassword"
          name="newPassword"
          :validations="$v.form.newPassword"
          auto-complete="off"
          :max-width="376"
        >
          <template #afterInput>
            <span class="show-pwd" @click="showPwd('newPasswordInput')">
              <svg-icon
                :icon="newPasswordInput === 'password' ? 'eye' : 'eye-open'"
              />
            </span>
          </template>
        </base-input>
      </base-section>
    </el-form>
  </page-layout>
</template>

<script>
import CancelRequest from '@/mixins/CancelRequest'
import PageLayout from '@/layouts/PageLayout'
import { minLength, required } from 'vuelidate/lib/validators'
import sha1 from 'js-sha1'
import SvgIcon from '@/components/SvgIcon'

export default {
  name: 'ChangePassword',
  components: {
    PageLayout,
    SvgIcon
  },
  mixins: [CancelRequest],
  data () {
    return {
      form: {
        oldPassword: '',
        newPassword: ''
      },
      key: '',
      saving: false,
      saveDisabled: true,
      loading: false,
      newPasswordInput: 'password',
      oldPasswordInput: 'password',
      newPasswordError: '',
      oldPasswordError: ''
    }
  },
  validations: {
    form: {
      oldPassword: {
        required,
        minLength: minLength(8)
      },
      newPassword: {
        required,
        minLength: minLength(8)
      }
    }
  },
  methods: {
    save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }
      const oldPincodeDigest = sha1(this.form.oldPassword)
      const newPincodeDigest = sha1(this.form.newPassword)
      this.$api.user.changePincode(
        {
          oldPincodeDigest,
          newPincodeDigest
        },
        {
          successNotification: this.$t('change_password.success_notification'),
          onSuccess: () => {
            this.$v.form.oldPassword.$reset()
            this.$v.form.newPassword.$reset()
            this.form.oldPassword = ''
            this.form.newPassword = ''
            this.key = this.$key()
          }
        }
      )
    },
    showPwd (prop) {
      this[prop] === 'password' ? (this[prop] = '') : (this[prop] = 'password')
      this.$nextTick(() => {
        this.$refs[prop].$refs.input.focus()
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.oldPasswordInput.$refs.input.focus()
    })
  }
}
</script>

<style lang="scss">
#changePassword {
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 8px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  .el-input-group__append {
    cursor: pointer;
  }
}
</style>
